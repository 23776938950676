<template>
  <v-app>
    <v-container class="fill-height d-flex align-center justify-center" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" sm="12" class="text-center">
          <v-img
            :src="image"
            alt="Valley Give&Take Logo"
            contain
            max-height="400"
            class="mb-4"
          ></v-img>

          <v-card class="pa-10" color="secondary" dark>
            <v-card-title class="display-1"
              >Welcome to Valley Give & Take</v-card-title
            >
            <v-card-text class="subtitle-1 mb-6">
              A place to give and take items for free in the Annapolis Valley
              during fall cleanup.
            </v-card-text>

            <v-btn color="primary" class="mx-2" large :to="{ name: 'MainApp' }"
              >Give</v-btn
            >
            <v-btn
              v-if="mapID"
              color="success"
              class="mx-2"
              large
              outlined
              :to="{ name: 'ViewerApp', params: { id: mapID } }"
            >
              Take
            </v-btn>

            <v-card-actions class="justify-center">
              <v-btn :to="{ name: 'UserItems' }">Manage my items</v-btn>
              <v-btn
                href="mailto:info@strollopia.com?subject=Feedback on Valley Give and Take"
                class="mx-2"
              >
                Contact Us
              </v-btn>
            </v-card-actions>
          </v-card>

          <!-- Acknowledgment Section -->
          <v-card class="mt-4 pa-3" color="grey lighten-4">
            <v-card-text class="text-center">
              Thanks to <strong>Divert NS</strong> for funding and to our other
              partners for their support.
            </v-card-text>
            <v-card-text class="text-center">
              <div>
                If you are using this platform, you agree to the following
                <a href="/terms" class="link">Terms and Conditions</a>
              </div>
            </v-card-text>
          </v-card>

          <!-- Next Project Section -->
          <v-card class="mt-6" flat>
            <v-card-text class="text-center">
              <div>
                <strong>Preview Our Next Positive Change Initiative </strong>
                <a href="http://ns.flagthis.ca/" target="_blank" class="link"
                  >Risk Awareness</a
                >
              </div>
            </v-card-text>
          </v-card>

          <!-- Survey Section -->
          <v-card class="mt-6" flat>
            <v-card-text class="text-center">
              <strong
                >Fill out the following survey for a chance to win a $100 gift
                card!</strong
              >
              <a
                href="https://forms.gle/xzf77sHx7mAzw4zt9"
                target="_blank"
                class="link"
                >Complete the Survey</a
              >
            </v-card-text>
          </v-card>

          <v-card class="mt-6" flat>
            <v-card-text>
              <a
                href="/ValleyGiveandTakebrochure.pdf"
                target="_blank"
                class="link"
                >About Us</a
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import image from "@/assets/logo.png";
import { fetchPublicOrgMaps } from "@/services.js";
import { useDataStore } from "@/stores/dataStore";

export default {
  name: "LandingPage",
  data() {
    return {
      image,
      mapID: null,
    };
  },
  setup() {
    const store = useDataStore();
    return { store };
  },
  async created() {
    const orgMapdata = await fetchPublicOrgMaps();
    const previewMap = orgMapdata.data.find(
      (map) => map.map_name === "public-map"
    );
    this.mapID = previewMap.map_obj || null;
  },
};
</script>

<style scoped>
.fill-height {
  max-height: 100vh;
}

.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  color: darkblue;
}
.v-card-text {
  padding: 0.3rem;
}
.v-card-title {
  white-space: normal;
}

@media (max-width: 600px) {
  .v-img {
    max-height: 200px !important;
  }
  .v-col-12 {
    flex: 0 0 100%;
    max-width: 100vw;
  }
}
</style>
