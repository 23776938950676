<template>
  <div class="map-fullscreen">
    <MapComponent />

    <!-- Info Button -->
    <v-btn
      icon
      class="info-button"
      @click="dialog = true"
    >
      <v-icon>mdi-information</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title>Instructions</v-card-title>
        <v-card-text>
          Choose a location on the map, then click "Add an Item" to post the item you would like to give.
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="controls-container">
      <div class="search-bar-container">
        <SearchBar />
      </div>

      <div class="buttons-container">
        <v-btn
          color="primary"
          class="add-item-button"
          large
          @click="showForm = true"
        >
          Add an Item
        </v-btn>

        <v-btn
          color="primary"
          class="home-button"
          large
          :to="{ name: 'LandingPage' }"
        >
          Home
        </v-btn>

        <v-btn
          color="success"
          class="manage-items-button"
          large
          outlined
          :to="{ name: 'UserItems' }"
        >
          Manage My Items
        </v-btn>
      </div>
    </div>

    <!-- Form Content -->
    <div v-if="showForm" class="content-container">
      <v-btn
        icon
        class="close-button"
        @click="showForm = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card class="mt-4" outlined>
        <v-card-text>
          <ItemForm @formSubmitted="handleFormSubmitted" />
        </v-card-text>
      </v-card>
    </div>

    <!-- Success Snackbar -->
    <v-snackbar v-model="snackbarVisible" :timeout="3000" color="success" location="top">
      Submitted For Review!
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbarVisible = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import MapComponent from "@/components/MapComponent.vue";
import ItemForm from "@/components/ItemForm.vue";
import SearchBar from "@/components/SearchBar.vue";

export default {
  components: {
    MapComponent,
    ItemForm,
    SearchBar,
  },
  data() {
    return {
      showForm: false,
      snackbarVisible: false,
      dialog: false,
      isMobile: false, 
    };
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
    handleFormSubmitted() {
      this.snackbarVisible = true;
      this.showForm = false;
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 600;
    }
  }
};
</script>

<style scoped>

.info-button {
  position: absolute;
  top: 80px;
  left: 8px;
  z-index: 1000;
}

.controls-container {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 20vw;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.close-button {
  background-color: rgb(154, 150, 150);
  color: white;
}

.content-container {
  background-color: white;
  padding: 20px;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;
  max-width: 600px;
  width: 90%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .search-bar-container {
    position: fixed;
    top: 10px;
    left: 56%;
    transform: translateX(-50%);
    z-index: 1000;
    width: 80vw;
  }

  .buttons-container {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
  .controls-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
    gap: 5px;
    padding: 10px;
    z-index: 1000;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    top: auto; 
  }


  .add-item-button, .home-button, .manage-items-button {
    font-size: 12px;
    padding: 8px;
    width: auto;
  }
}
</style>
